<template>
    <div class="dashboard py-5 py-lg-6">
        <section v-if="activeUser">
            <div class="row">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-lg-12 mb-5">
                    <div class="card shadow-sm show-worker-earnings p-5 border">
                        <div class="row g-4 text-center font-weight-bold justify-content-between">
                            <div class="col-6 col-md-3">
                                <p class="mb-0">TOTAL INCOME</p>
                                <h2 class="mb-0 text-success">${{earning.income | money_format}}</h2>
                            </div>
                            <div  class="col-6 col-md-3">
                                <p class="mb-0">WORKER GAIN</p>
                                <h2 class="mb-0 text-success">${{earning.gain | money_format}}</h2>
                            </div>
                            <div  class="col-6 col-md-3">
                                <p class="mb-0">PENDING</p>
                                <h2 class="mb-0 text-success">${{earning.pending | money_format}}</h2>
                            </div>
                            <div class="col-6 col-md-3">
                                <p class="mb-0">WITHDRAWN</p>
                                <h2 class="mb-0 text-success">${{earning.withdrawn | money_format}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <h4 class="fs-18 lh-base fw-bold mb-0"><span class="text-success">Order summary</span></h4>
                        <p class="mb-0 mt-2 pt-1">There is a total of <strong>{{result.orders.total}}</strong> order{{pluralize(result.orders.total)}} available in your account.
                            <strong>{{result.orders.active}}</strong> {{pluralizeIsAre(result.orders.active)}} active, <strong>{{result.orders.delivered}}</strong> {{pluralizeIsAre(result.orders.delivered)}} delivered, and
                            <strong>{{result.orders.completed}}</strong> {{pluralizeIsAre(result.orders.completed)}} completed.
                        </p>
                    </div>
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-sm-4 border">
                                    <div class="py-4 px-3">
                                        <h5 class="text-muted text-uppercase fs-13">Active</h5>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <i class="mdi mdi-progress-clock display-6 mr-2 text-muted"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h2 class="mb-0">{{result.orders.active}}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 border">
                                    <div class="py-4 px-3">
                                        <h5 class="text-muted text-uppercase fs-13">Delivered</h5>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <i class="mdi mdi-truck-check-outline display-6 mr-2 text-muted"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h2 class="mb-0">{{result.orders.delivered}}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 border">
                                    <div class="py-4 px-3">
                                        <h5 class="text-muted text-uppercase fs-13">Completed</h5>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <i class="mdi mdi-trophy-outline display-6 mr-2 text-muted"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <h2 class="mb-0">{{result.orders.completed}}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="header-title mb-1">Your delivery summary</h4>
                            <p class="mb-3"> You have delivered on {{result.deliveries.total}} order{{pluralize(result.deliveries.total)}}.</p>
                            <div class="table-responsive mb-0">
                                <table class="table table-sm table-centered mb-0 font-14">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Status</th>
                                            <th>Count</th>
                                            <th style="width: 30%;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>In Progress</td>
                                            <td>{{result.deliveries.progress}}</td>
                                            <td>
                                                <div class="progress" style="height: 3px;">
                                                    <div role="progressbar" aria-valuemax="100"
                                                        class="progress-bar" :style="`width: ${getDevInp(result.deliveries.progress)}%;`"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Finalist</td>
                                            <td>{{result.deliveries.finalist}}</td>
                                            <td>
                                                <div class="progress" style="height: 3px;">
                                                    <div role="progressbar" aria-valuemax="100"
                                                        class="progress-bar bg-info" :style="`width: ${getDevInp(result.deliveries.finalist)}%;`"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Won</td>
                                            <td>{{result.deliveries.winner}}</td>
                                            <td>
                                                <div class="progress" style="height: 3px;">
                                                    <div role="progressbar" aria-valuemax="100"
                                                        class="progress-bar bg-success" :style="`width: ${getDevInp(result.deliveries.winner)}%;`"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Blocked</td>
                                            <td>{{result.deliveries.blocked}}</td>
                                            <td>
                                                <div class="progress" style="height: 3px;">
                                                    <div role="progressbar" aria-valuemax="100"
                                                        class="progress-bar bg-danger" :style="`width: ${getDevInp(result.deliveries.blocked)}%;`"></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Eliminated</td>
                                            <td>{{result.deliveries.eliminated}}</td>
                                            <td>
                                                <div class="progress" style="height: 3px;">
                                                    <div role="progressbar" aria-valuemax="100"
                                                        class="progress-bar bg-warning" :style="`width: ${getDevInp(result.deliveries.eliminated)}%;`"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row pt-5 pt-lg-6">
                <div class="col-12">
                    <order-table />
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import OrderTable from "@/components/order/OrderTable.vue"
import UserEmailVerification from "@/views/account/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        OrderTable,
        UserEmailVerification
    },
    data(){
        return{
            result: {
                "orders": {
                    "total": 0,
                    "active": 0,
                    "delivered": 0,
                    "completed": 0
                },
                "deliveries": {
                    "total": 0,
                    "progress": 0,
                    "finalist": 0,
                    "winner": 0,
                    "blocked": 0,
                    "eliminated": 0
                }
            },
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        earning(){
            return this.activeUser.earning
                ? this.activeUser.earning
                : {
                    income: 0,
                    gain: 0,
                    pending: 0,
                    withdrawn: 0,
                  }
        },
        pluralize(){
            return (i) => i > 1 ? 's':''
        },
        pluralizeIsAre(){
            return (i) => i > 1 ? 'are':'is'
        },
        getDevInp(){
            return (v) => Math.round(v/(this.result.deliveries.total*0.01))
        }
    },
    created(){
        this.$http.get('/analytics')
            .then((response) => {
                if(response.data.success){
                    this.result = response.data.data.result;
                }
            })
    }

}
</script>

<style scoped>
.show-worker-earnings h2{
    font-weight: bold;
}
@media(max-width:567px){
    .show-worker-earnings h2{
        font-size:22px;
    }
    .show-worker-earnings p{
        font-size:14px;
    }
}
</style>
